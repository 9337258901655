import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
} from "reactstrap";

interface Props {
  title: string;
  links: Array<{ label: string; onClick: () => void }>;
  contact: {
    phone: string;
    email: string;
  };
  isVisible?: boolean;
}

export const Navigation: React.FC<Props> = ({
  title,
  links,
  contact,
  isVisible,
}) => {
  return isVisible ? (
    <Navbar
      color="light"
      light
      expand="md"
      style={{ position: "fixed", top: 0, width: "100%", zIndex: "999" }}
    >
      <NavbarBrand>{title}</NavbarBrand>
      <Nav className="mr-auto" navbar>
        {links.map(({ label, onClick }, index) => (
          <NavItem key={index}>
            <a href="#about-anchor">About</a>
          </NavItem>
        ))}
      </Nav>
      <NavbarText>{contact.phone}</NavbarText>
      <NavbarText>{contact.email}</NavbarText>
      {/* //! Here we can fill social links - facebook, instagram, tiktok. */}
    </Navbar>
  ) : (
    <></>
  );
};

export default Navigation;
