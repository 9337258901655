import React from "react";
import { Carousel } from "react-bootstrap";
import img1 from "../Assets/img1.jpg";
import img2 from "../Assets/img2.jpg";
import img3 from "../Assets/img3.jpg";
import img4 from "../Assets/img4.jpg";

export const CarouselContent = () => {
  return (
    <div className="alternate-section" id="carousel-anchor">
      <div className="mt-10 mb-20 text-center carousel-section carousel-wrap">
        <span className="sm:text-5xl text-3xl cattery-alternate-text-color cattery-about-us">
          Our Gallery
        </span>
        <Carousel>
          <Carousel.Item>
            <img className="d-block w-100 h-100" src={img1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img2} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img3} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={img4} alt="Fourth slide" />
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
