import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Hero } from "./Components/Hero";
import { FAQ } from "./Components/FAQ";
import { CarouselContent } from "./Components/CarouselContent";
import { AvailableKittens } from "./Components/AvailableKittens";
import { AboutUs } from "./Components/AboutUs";
import { Navigation } from "./Components/Navigation";
import { Credentials } from "./Components/Credentials";
import { ContactUs } from "./Components/ContactUs";

function App() {
  const links = [
    { label: "Home", onClick: () => console.log("home") },
  ];
  return (
    <div className="App">
      <Navigation
        links={links}
        title="Royalty Toygers"
        contact={{
          phone: "555-555-5555",
          email: "cattery@example.com"
        }}
        isVisible={false}
      />
      <Hero />
      <AboutUs />
      <AvailableKittens />
      <CarouselContent />
      <FAQ />
      <Credentials />
      <ContactUs />
    </div>
  );
}

export default App;
