import React from "react";
import "./Hero.css";
import cattery_logo from "../Assets/cattery-logo.png";
import icon_facebook from "../Assets/facebook.png";
import icon_tiktok from "../Assets/tiktok.png";
import icon_instagram from "../Assets/instagram.png";
import seraphina_hero from "../Assets/seraphina_hero.jpg";

export const Hero: React.FC = () => {
  return (
    <>
      <div className="header-wrap app-section hero-section">
        <div className="flex flex-row items-center flex-wrap-reverse hero-image-links-wrap">
          <img className="seraphina-hero-image" src={seraphina_hero} />
          <div className="icon-links-wrap">
            <img className="cattery-logo" src={cattery_logo} />
            <span className="sm:text-7xl text-4xl sm:mt-3 mt-4 royalty-cattery-name">
              Royalty Toygers
            </span>
            <span className="cattery-icon-wrap"></span>
            <span className="flex flex-col">
              <a href="#about-anchor">About Us</a>
              <a href="#available-anchor">Available Kittens</a>
              <a href="#carousel-anchor">Gallery</a>
              <a href="#faq-anchor">FAQ</a>
              <a href="#credentials-anchor">Credentials</a>
              <a href="#contact-anchor">Contact Us</a>
            </span>
            <div className="cattery-phone-email-wrap-hero">
              <span className="cattery-text-color cattery-phone mr-1">
                +1 850-819-3636
              </span>
              <span className="cattery-icon-separator"></span>
              <span className="cattery-text-color cattery-phone ml-1 mr-1">
                royaltytoygers@gmail.com
              </span>
            </div>
            <div className="flex cattery-social-icons-wrap">
              <a href="https://www.facebook.com/Royalty-Toygers-203713951692418/">
                <img className="cattery-icon" src={icon_facebook} />
              </a>
              <a href="https://www.tiktok.com/@wandagoodreau">
                <img className="cattery-icon" src={icon_tiktok} />
              </a>
              <a href="https://www.instagram.com/royaltytoygerscattery/">
                <img className="cattery-icon" src={icon_instagram} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
