import React from "react";
import "./Hero.css";
import tica_ethics_certificate from "../Assets/tica_ethics_certificate.jpg";

export const Credentials: React.FC = () => {
  return (
    <div className="alternate-section">
      <div className="header-wrap app-section" id="credentials-anchor">
        <span className="sm:text-7xl text-4xl sm:mt-3 mt-4 royalty-cattery-name cattery-alternate-text-color">
          Credentials
        </span>
        <span className="cattery-about-us cattery-text-color cattery-alternate-text-color">
          Below is a TICA certificate outlining our code of ethics. These are
          very important to us at Royalty Toygers Cattery.
        </span>
        <img className="tica-certificate-image" src={tica_ethics_certificate} />
      </div>
    </div>
  );
};
