import React from "react";
import "./Hero.css";
import current_kittens from "../Assets/current_kittens.jpg";

export const AvailableKittens: React.FC = () => {
  return (
    <div
      className="header-wrap app-section available-section"
      id="available-anchor"
    >
      <span className="sm:text-7xl text-4xl sm:mt-3 mt-4 royalty-cattery-name">
        Available Kittens
      </span>
      <span className="cattery-about-us cattery-text-color">
        There are currently six kittens available for sale. Please contact us
        via facebook or the listed number for more specific information. Also, we are
        happy to do a FaceTime call with potential cat families to help you choose
        available kittens. If you are in the area, you are also welcome to visit
        us so you can see our home and meet the kittens in person.
      </span>
      <img className="current-kittens-image" src={current_kittens} />
    </div>
  );
};
