import React from "react";
import "./FrequentlyAsked.css";

export const FAQ: React.FC = () => {
  return (
    <div className="footer-wrap cattery-text-color FAQ-section" id="faq-anchor">
      <span className="sm:text-7xl text-4xl sm:mt-3 mt-4 mb-4 cattery-text-color">
        Frequently Asked Questions
      </span>
      <span className="cattery-faq-qa">
        <span className="cattery-faq-question sm:text-3xl text-xl">
          Is there a waitlist?
        </span>
        <span className="footer-info-block cattery-text-color cattery-about-us">
          If you are interested in one of our future kittens, we accept requests
          free of charge to be placed on our waiting list. We update our
          facebook with all information during the cat's pregnancy, as well as
          when the kittens arrive. Once the kittens are born, we require a
          deposit, with pricing depending on the customers preferences and
          depending on the quality of kitten.
        </span>
      </span>
      <span className="cattery-faq-qa">
        <span className="cattery-faq-question sm:text-3xl text-xl">
          Do I need to travel to you?
        </span>
        <span className="footer-info-block cattery-text-color cattery-about-us">
          No, you do not! We are able to ship kittens all over the world, with
          the buyers paying for shipping. There are many quality shipping
          options available, and we can provide a list of options if interested.
          Just reach out!
        </span>
      </span>
      <span className="cattery-faq-qa">
        <span className="cattery-faq-question sm:text-3xl text-xl">
          What is the best way to contact you?
        </span>
        <span className="cattery-about-us cattery-text-color">
          The best way to contact us is through Facebook messenger, or by
          calling!
        </span>
      </span>
      <span className="cattery-faq-qa">
        <span className="cattery-faq-question sm:text-3xl text-xl">
          How does pricing work?
        </span>
        <span className="cattery-about-us cattery-text-color">
          The pricing depends on the quality of the kitten and intention (pet,
          breeding or show quality, for example). When kittens are available,
          pricing will be discussed via private message on Facebook or during a
          telephone call. Please inquire if you are interested!
        </span>
      </span>
    </div>
  );
};
