import React from "react";
import "./Hero.css";
import icon_facebook from "../Assets/facebook.png";
import icon_tiktok from "../Assets/tiktok.png";
import icon_instagram from "../Assets/instagram.png";

export const ContactUs: React.FC = () => {
  return (
    <div className="header-wrap app-section about-section" id="contact-anchor">
      <span className="sm:text-7xl text-4xl sm:mt-3 mt-4 royalty-cattery-name">
        Contact Us
      </span>
      <span className="cattery-about-us">
        Our toyger kittens will be a great addition to your family. We are
        always happy to assist you in your choice. Please feel free to contact
        us using the methods below.
      </span>
      <div className="cattery-phone-email-wrap-hero flex-wrap">
        <span className="cattery-text-color cattery-phone mr-1">
          +1 850-819-3636
        </span>
        <span className="cattery-icon-separator"></span>
        <span className="cattery-text-color cattery-phone ml-1 mr-1">
          royaltytoygers@gmail.com
        </span>
        <span className="cattery-icon-separator"></span>
        <a href="https://www.facebook.com/Royalty-Toygers-203713951692418/">
          <img className="cattery-icon" src={icon_facebook} />
        </a>
        <a href="https://www.tiktok.com/@wandagoodreau">
          <img className="cattery-icon" src={icon_tiktok} />
        </a>
        <a href="https://www.instagram.com/royaltytoygerscattery/">
          <img className="cattery-icon" src={icon_instagram} />
        </a>
      </div>
    </div>
  );
};
