import React from "react";
import "./Hero.css";

export const AboutUs: React.FC = () => {
  return (
    <div className="alternate-section">
      <div className="header-wrap app-section about-section" id="about-anchor">
        <span className="sm:text-7xl text-4xl sm:mt-3 mt-4 royalty-cattery-name cattery-alternate-text-color">
          About Us
        </span>
        <span className="cattery-about-us cattery-alternate-text-color">
          Welcome to the wonderful world of Toyger cats! We are a small,
          boutique, family-owned <a href="https://tica.org">TICA-registered</a>{" "}
          cattery located in Tallahassee, FL. We believe in fostering trust
          between cats and their humans, and as a result, our cats and kittens
          are integrated members of our family and receive daily attention and
          the best of everything. We also prioritize providing affection and
          human interaction to all of our pets, resulting in well-mannered and
          adorable baby toygers.
        </span>
      </div>
    </div>
  );
};
